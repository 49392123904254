import { supabase } from '~/services/supabase'
import { roles } from '~/translations/account-roles'
import { Job } from '~/types/comic/chapter'
import { DeleteSeriesPermissionPayload } from '~/types/database/permissions'

class Role{
  index: number
  value: string
  label: string

  constructor(permission?: string | null){
    const index = roles.findIndex(entry => entry.value === permission)
    this.index = index !== -1 ? index : 0
    this.value = roles[this.index].value
    this.label = roles[this.index].label
  }
}

const requiredRole = (step: Job) => {
  return roles.find(role => (role.jobs as unknown as string[]).includes(step))?.value ?? 'admin'
}

const hasReached = (targetStep: Job, currentStep: string) => {
  const currentJob = new Role(currentStep)
  const targetJob = new Role(targetStep)
  return targetJob.index >= currentJob.index
}

const revokeSeriesPermission = async ({ seriesId }: DeleteSeriesPermissionPayload) => {
  const { error } = await supabase
    .from('series_permissions')
    .update({
      revoked: true
    })
    .eq('series_id', seriesId)
  if (error) {
    reportError(error)
    throw error
  }
  return true
}

export {
  Role,
  requiredRole,
  hasReached,
  revokeSeriesPermission
}